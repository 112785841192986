.modal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 100;
  pointer-events: none;
  opacity: 0;
  transition: opacity 500ms ease;
}

.modal.active {
  opacity: 1;
  pointer-events: all;
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background-color: var(--backdrop);
}

.modalHeader {
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
  align-items: center;
  border-bottom: 1px var(--border) solid;
}

.modalTitle {
  width: calc(100% - 24px);
  overflow: hidden;
  text-overflow: ellipsis;
}

.modalWrap {
  position: relative;
  z-index: 1;
  max-width: 768px;
  border-radius: 17px;
  background-color: var(--back);
  width: 100%;
  margin: 24px;
  transform: scale(0.9);
  box-shadow: 0px 0px 17px 2px rgba(0, 0, 0, 0.08);
  transition: transform 500ms ease;
}

.modalContent {
  padding: 24px;
  max-height: calc(100vh - 240px);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.modal.active .modalWrap {
  transform: none;
}

.close {
  cursor: pointer;
  transition: transform 0.3s ease;
}

.close:hover {
  transform: scale(1.08);
}
