.Block {
  position: relative;
  padding: 56px 0;
}

.Block h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 1.2;
  color: var(--goldText);
  margin: 0 0 24px 0;
}

.Block p {
  margin: 0;
}

.icon {
  position: absolute;
  z-index: -1;
  opacity: 0.15;
  height: 128px;
  top: 0;
}

@media (max-width: 560px) {
  .Block {
    padding: 32px 0;
  }
  .icon {
    height: 64px;
  }
  .Block h2 {
    font-size: 16px;
  }
  .Block p {
    font-size: 14px;
  }
}
