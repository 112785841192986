.slide {
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    var(--gold) 0%,
    var(--gold) 31.555%,
    var(--dark) 31.556%,
    var(--dark) 100%
  );
}

.slide > div {
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.slideImage {
  width: 40%;
}

.slideImage img {
  max-width: 100%;
}

.slidePerson {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 60%;
  padding: 64px 48px;
  color: #ffffff;
}

.label {
  text-transform: uppercase;
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2em;
  color: #747474;
  margin-bottom: 32px;
}

.slidePerson h3 {
  font-weight: bold;
  font-size: 35px;
  line-height: 43px;
  text-transform: uppercase;
  margin: 0;
}

.slidePerson p {
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 188.1%;
  color: #f5f5f5;
  margin-top: 48px;
}

.slideButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.slideButtonGroup {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 24px;
}

.slideButtonArrows {
  display: flex;
  align-items: center;
}

.arrowButton {
  color: var(--gold);
  appearance: none;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

.arrowButton svg {
  display: block;
}

.blocksSlider .block {
  margin: 24px;
}

@media (max-width: 900px) {
  .slide {
    background: var(--dark);
  }
  .slide > div {
    display: block;
  }
  .slideImage {
    margin: auto;
    height: 256px;
    width: 256px;
    overflow: hidden;
    border-radius: 50%;
    margin-top: 48px;
    background-color: var(--gold);
  }
  .fix {
    transform: translate(13px, 0);
  }
  .slidePerson {
    width: 100%;
    height: unset;
  }
  .slideButtons {
    margin-top: 48px;
  }
  .label {
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: 16px;
  }
  .slidePerson h3 {
    font-size: 24px;
  }
  .slidePerson p {
    font-size: 16px;
    margin-top: 16px;
  }
}

@media (max-width: 768px) {
  .label {
    display: none;
  }
  .slide {
    position: relative;
  }
  .slideImage {
    height: 143px;
    width: 143px;
  }
  .slidePerson {
    padding: 24px 0;
  }
  .slidePerson h3 {
    font-size: 20px;
    text-align: center;
    padding: 0 24px;
    line-height: 1.2;
  }
  .slidePerson p {
    font-size: 13px;
    text-align: center;
  }
  .slideButtons {
    justify-content: center;
    margin-top: 24px;
  }
  .hideMobile {
    display: none;
  }
  .slideButtonArrows {
    position: absolute;
    display: flex;
    justify-content: space-between;
    top: 220px;
    padding: 0 12px;
    left: 0;
    width: 100%;
  }
  .slideButtonGroup button {
    margin: auto !important;
    font-size: 14px;
    padding: 6px 10px;
    min-width: 136px;
  }
}
