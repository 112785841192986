.Footer {
  background-color: var(--dark);
  color: var(--back);
  padding: 48px 0;
}

.logo {
  width: 64px;
}

.logo img {
  max-width: 100%;
}

.flex {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.menu {
  display: flex;
  align-items: flex-start;
}

.menu > :first-child {
  margin-right: 32px;
}

.links a,
.tels a {
  display: block;
  color: #adadad;
  font-size: 14px;
  line-height: 2;
}

.copyright {
  color: #adadad;
  display: block;
  width: fit-content;
  font-size: 14px;
  margin-top: 32px;
}

@media (max-width: 800px) {
  .flex {
    flex-direction: column;
  }
  .menu {
    margin: 24px 0;
  }
}

@media (max-width: 520px) {
  .menu {
    flex-direction: column;
  }
  .logo {
    width: 48px;
  }
  .links a,
  .tels a {
    font-size: 13px;
  }
  .menu > :first-child {
    margin: 0 0 24px 0;
  }
  .copyright {
    font-size: 12px;
  }
}
