.hero > div {
  position: relative;
}

.heroImage {
  max-width: 540px;
  margin: auto;
}

.scroll{
  bottom: 32px;
  position: fixed;
  width: 40px;
  left: 50%;
  color: #555555;
  transform: translateX(-50%);
  pointer-events: none;
  opacity: 1;
  transition: opacity .2s ease;
}

.scroll svg{
  animation: toBottom 1.5s ease infinite;
}

.scroll.scrolled{
  opacity: 0;
}

@keyframes toBottom{
  0%{
    opacity: 1;
  }
  50%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
}

.heroImage img {
  max-width: 100%;
}

.heroImage h1 {
  position: absolute;
  top: calc(50% - 90px);
  margin: 0;
  color: var(--goldText);
  opacity: 0.4;
  left: 0;
  line-height: 1;
  font-size: 180px;
  text-align: center;
  overflow: hidden;
}

.aboutWrap {
  background-color: var(--gray);
}

.about {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 64px 0;
}

.about .image {
  width: 400px;
}

.about .image img {
  max-width: 100%;
}

.about .text {
  width: calc(100% - 424px);
}

.about .text h2 {
  font-family: Heuristica, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 48px;
  line-height: 58px;
  text-transform: uppercase;
  color: var(--darkText);
  margin: 0 0 32px 0;
}

.about .text p {
  font-weight: 500;
  font-size: 16px;
  line-height: 188.1%;
  text-align: justify;
  margin: 0;
}

.blocks {
  padding: 120px 0;
  background-color: var(--gray);
}

.blocks h2 {
  color: var(--darkText);
  font-family: Heuristica, serif;
  font-weight: normal;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
}

.blocksGrid {
  padding-top: 64px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
}

.block {
  background: #ffffff;
  box-shadow: 0px 0px 17px 2px rgba(0, 0, 0, 0.08);
  border-radius: 19px;
  padding: 48px 20px;
  text-align: center;
}

.block img{
  height: 60px;
}

.block h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 17px;
  min-height: 42px;
  line-height: 21px;
  color: var(--goldText);
  margin: 28px 0 16px 0;
}

.block p {
  font-weight: 500;
  font-size: 15px;
  line-height: 21px;
  margin: 0;
}

.docsGrid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 40px;
}

.docsGrid img {
  max-width: 100%;
}

.docs {
  padding: 128px 0;
}

.docs h2 {
  color: var(--darkText);
  font-family: Heuristica, serif;
  font-weight: normal;
  font-size: 32px;
  line-height: 38px;
  text-align: center;
  text-transform: uppercase;
}

.docs p {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 188.1%;
  text-align: center;
  margin-bottom: 72px;
}

.partners {
  background-color: var(--gray);
  padding: 48px 0;
}

.label {
  font-weight: bold;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.2em;
  color: #747474;
}

.partners h2 {
  font-family: Heuristica, serif;
  font-weight: normal;
  font-size: 35px;
  line-height: 42px;
  text-transform: uppercase;
}

.logos {
  padding: 48px 0;
  max-width: 800px;
  margin: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 32px 96px;
  grid-template-areas: "l1 l2 l2 l3" "l4 l5 l6 l7" ". . . .";
}

.logo {
  display: flex;
  align-items: center;
}

.logo img {
  max-width: 100%;
}

.hideDesktop {
  display: none;
}

.blockWrap {
  padding: 24px 32px;
  width: 100%;
}

.blocksSlider,
.docsSlider {
  position: relative;
  margin: 0 -24px;
}

.arrows {
  position: absolute;
  top: calc(50% - 16px);
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.arrows button {
  appearance: none;
  padding: 0;
  border: none;
  color: var(--goldText);
  background-color: transparent;
  cursor: pointer;
  outline: none !important;
}

.arrows button svg {
  display: block;
}

.docsSlider .doc {
  width: 100%;
  padding: 32px;
}

.doc a {
  width: 100%;
}

.doc a img {
  max-width: 100%;
}

@media (max-width: 1072px) {
  .heroImage h1 {
    top: calc(50% - 17vw);
    font-size: 17vw;
  }
}

@media (max-width: 840px) {
  .scroll{
    display: block;
  }
  .about {
    display: block;
    position: relative;
  }
  .about .image {
    position: absolute;
    z-index: 0;
    top: -10%;
    right: -24px;
    height: 120%;
    width: unset;
    overflow-x: hidden;
    filter: saturate(0%);
    opacity: 0.2;
  }
  .about .image img {
    height: 100%;
    max-width: unset;
    transform: translateX(20%);
  }
  .about .text {
    position: relative;
    width: 100%;
    text-align: center;
    z-index: 1;
  }
  .about .text h2 {
    font-size: 24px;
    line-height: 1.2;
  }
  .about .text p {
    font-size: 15px;
    line-height: 1.5;
    text-align: center;
  }
  .hideMobile {
    display: none;
  }
  .hideDesktop {
    display: block;
  }
  .blocks,
  .docs {
    padding: 64px 0;
  }
  .blocks h2,
  .docs h2 {
    font-size: 20px;
    line-height: 1.2;
  }
  .docs p {
    margin: auto;
    font-size: 14px;
    line-height: 1.2;
    max-width: 240px;
  }
  .label {
    display: none;
  }
  .partners h2 {
    text-align: center;
    font-size: 20px;
    line-height: 1.2;
  }
  .logos {
    gap: 24px 32px;
  }
}
