.Navbar {
  padding: 24px 0;
}

.desktop,
.mobile {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.brand img {
  height: 64px;
  filter: invert(1)
}

.links {
  display: flex;
  align-items: center;
}

.links a {
  color: inherit;
  text-transform: uppercase;
  margin-right: 24px;
  font-size: 14px;
}

.mobile {
  display: none !important;
}

.menuWrap {
  display: none;
}

.tel {
  font-weight: bold;
  color: inherit;
}

.menu .tel {
  display: flex;
  align-items: inherit;
  margin-bottom: 24px;
}

.menu .tel svg {
  display: block;
  margin-right: 4px;
}

.menu .tel span {
  display: block;
}

@media (max-width: 920px) {
  .menuWrap {
    position: fixed;
    display: block;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1000;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.4s ease;
  }
  .burger {
    cursor: pointer;
  }
  .open .menuWrap {
    opacity: 1;
    pointer-events: all;
  }
  .menuWrap .backdrop {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: -1;
  }
  .menuWrap .menu {
    position: relative;
    z-index: 1;
    width: 240px;
    background-color: #ffffff;
    height: 100%;
    padding: 24px;
    transform: translateX(-100%);
    transition: transform 0.4s ease;
  }
  .open .menuWrap .menu {
    transform: none;
  }
  .menu .links {
    flex-direction: column;
    align-items: flex-start;
    margin: 48px 0;
  }
  .menu .links a {
    margin-bottom: 8px;
  }
  .desktop {
    display: none !important;
  }
  .mobile {
    display: flex !important;
  }
  .mobile .brand img {
    height: 36px;
  }
  .links a {
    margin: 0;
  }
}
