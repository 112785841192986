.Page {
  padding: 24px 0;
}

.Page h1 {
  font-family: Heuristica, serif;
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 1.2;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 24px;
  color: var(--goldText);
}

.personImage {
  width: 220px;
  height: 220px;
  overflow: hidden;
  margin-right: 24px;
  border-radius: 50%;
}

.personImage img {
  max-width: 100%;
}

.person {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}

.personInfo {
  width: calc(100% - 244px);
}

.personInfo h2 {
  font-size: 26px;
  text-transform: uppercase;
  line-height: 1.2;
  margin: 0 0 8px 0;
  max-width: 300px;
}

.fix {
  transform: translate(3%, 0);
}

.about {
  font-size: 16px;
  line-height: 188.1%;
}

.hideDesktop {
  display: none;
}

@media (max-width: 768px) {
  .personImage {
    height: 120px;
    width: 120px;
    margin-right: 8px;
  }
  .personInfo {
    width: calc(100% - 128px);
  }
  .personInfo h2 {
    max-width: 100%;
    font-size: 18px;
    color: var(--darkText);
  }
  .personInfo p {
    display: none;
  }
  .about {
    font-size: 14px;
  }
  .hideDesktop {
    display: block;
  }
}

@media (max-width: 560px) {
  .Page h1 {
    font-size: 20px;
    text-align: left;
  }
}
