@font-face {
  font-family: "Heuristica";
  src: url("Heuristica-Bold.woff2") format("woff2"),
    url("Heuristica-Bold.woff") format("woff"),
    url("Heuristica-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Heuristica";
  src: url("Heuristica-BoldItalic.woff2") format("woff2"),
    url("Heuristica-BoldItalic.woff") format("woff"),
    url("Heuristica-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Heuristica";
  src: url("Heuristica-Regular.woff2") format("woff2"),
    url("Heuristica-Regular.woff") format("woff"),
    url("Heuristica-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Heuristica";
  src: url("Heuristica-Italic.woff2") format("woff2"),
    url("Heuristica-Italic.woff") format("woff"),
    url("Heuristica-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
