.Form {
  text-align: center;
}

.Form input {
  width: 100%;
  appearance: none;
  display: block;
  font-size: 16px;
  line-height: 1.25;
  padding: 16px;
  border: 1px var(--border) solid;
  border-radius: 8px;
}

.inModal.Form input {
  border-color: var(--border);
}

.Form input:focus {
  outline: none;
  border-color: var(--gold);
}

.Form .field {
  margin-bottom: 24px;
}

.Form button {
  width: fit-content;
  margin: 48px auto;
}

.Form p {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  max-width: 420px;
  color: var(--goldText);
  font-family: Heuristica, serif;
  margin: auto;
  margin-bottom: 32px;
}
