@import "./Assets/Fonts/stylesheet.css";

body {
  --gold: #b7a484;
  --dark: #151f36;
  --gray: #f5f5f5;
  --back: #ffffff;
  --goldText: #867457;
  --darkText: #17476c;
  --text: #222222;
  --backdrop: rgba(255, 255, 255, 0.5);
  --border: #eee;
  color: var(--text);
  background-color: var(--back);
  font-family: "Montserrat", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}

body.scrollDisabled {
  overflow: hidden;
}

.fab {
  display: block;
  appearance: none;
  position: fixed;
  bottom: 24px;
  border: none;
  right: 24px;
  padding: 20px;
  border-radius: 50%;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.2);
  background-color: #12b87f;
  outline: none !important;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.fab:hover {
  transform: scale(1.05);
}

.fab img {
  display: block;
}
