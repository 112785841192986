.cards {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  padding: 72px 0;
}

.card {
  background-size: cover;
  background-position: center;
  overflow: hidden;
  border-radius: 19px;
}

.cardContent {
  background-color: rgba(0, 0, 0, 0.63);
  color: #ffffff;
  padding: 48px 24px;
  text-align: center;
  height: 100%;
}

.cardContent h3 {
  font-family: Heuristica, serif;
  font-size: 22px;
  margin: 0;
  padding: 0 8px;
  text-transform: uppercase;
}

.cardContent p {
  font-size: 14px;
  line-height: 1.8;
  margin: 22px 0;
}

.cardButton {
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 13px;
}

.cardButton > * {
  margin: auto;
}

@media (max-width: 960px) {
  .cards {
    gap: 24px;
  }
  .cardContent {
    padding: 48px 20px;
  }
}

@media (max-width: 840px) {
  .cards {
    grid-template-columns: 1fr;
  }
  .cardButton {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
  .cardButton :first-child {
    margin: unset;
    margin-left: auto;
  }
  .cardButton :last-child {
    margin: unset;
    margin-right: auto;
  }
}

@media (max-width: 460px) {
  .cardContent h3 {
    font-size: 20px;
  }
  .cardContent p {
    font-size: 13px;
  }
  .cardButton {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr;
  }
  .cardButton > * {
    margin: auto !important;
  }
  .cardButton button {
    font-size: 14px;
    padding: 6px 10px;
    min-width: 136px;
  }
}
