.Button {
  appearance: none;
  border: none;
  display: block;
  width: fit-content;
  min-width: 160px;
  background-color: var(--gold);
  color: #ffffff;
  font-family: Heuristica, serif;
  font-weight: bold;
  font-size: 17px;
  padding: 9px 12px;
  outline: none !important;
  cursor: pointer;
  transition: filter 0.2s ease;
}

.Button:hover {
  filter: brightness(110%);
}

.Button:active {
  filter: brightness(80%);
}
